<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-if="data!=null"
            :items="data"
            :search="search"
            :headers="dataHeader"
            class="elevation-1 mt-5">

            <template
                v-slot:top>
                <v-toolbar
                    width="auto"
                    height="auto"
                    class="py-3"
                    flat>
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row 
                                class="mb-3">
                                <v-col>
                                    <v-toolbar-title
                                        class="font-weight-bold text-h4 my-3">
                                        User Detail Survey
                                    </v-toolbar-title>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-text-field
                                    dense 
                                    outlined 
                                    clearable 
                                    v-model="search"
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.answer_created_at="{item}">
                <span>
                    {{ convertTimeZone(item.answer_created_at) }}
                </span>
            </template>
            <template v-slot:item.index={item}>
                <span>
                    {{ data.indexOf(item)+1 }}
                </span>
            </template>
            <template v-slot:item.question_company_size="{item}">
                <span 
                    v-if="item.question_company_size===null">
                    -  
                </span>
                <span
                    v-else>
                {{ item.question_company_size }}
                </span>
            </template>
            <template
                v-slot:item.question_company_industry="{item}">
                <span
                    v-if="item.question_company_industry==null">
                    -
                </span>
                {{ item.question_company_industry }}
            </template>
            <template
                v-slot:item.question_why_sign_up_taxpod="{item}">
                <ul
                    v-for="(reason,index) in JSON.parse(item.question_why_sign_up_taxpod)"
                    :key="index">
                    <li>
                        <span>
                            {{ reason }}
                        </span>
                    </li>
                </ul>
            </template>
            
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        search:"",
        dataHeader:[
            {
                text:'#',
                value:'index',
                align:'start',

            },
            {
                text:'Company',
                value:'company_name'
            },
            {
                text:'User',
                value:'fname',
            },
            {
                text:'Company industry',
                value:'question_company_industry',

            },
            {
                text:'Position',
                value:'question_user_position',
            },
            {
                text:'Company size',
                value:'question_company_size',
            },
            {
                text:'Why Sign Up',
                value:'question_why_sign_up_taxpod'
            },
            {
                text:'Answer date',
                value:'answer_created_at'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
        
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            this.api.isLoading = false;
            if(resp.class=="getAllUserDetailSurveyReport"){
             
                this.data = resp.data;
            }
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchUserDetailSurveyListApi = this.fetchUserDetailSurveyList();
            this.$api.fetch(fetchUserDetailSurveyListApi);
        },
        fetchUserDetailSurveyList(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            let url = process.env.VUE_APP_SERVER_API.replace('v1','v4');
            tempApi.url = url+"/survey/report/user-detail";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')

        }
    }
}
</script>